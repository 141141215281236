.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/* Color slider  */

.c-1-color {
    background: red;
}
.c-2-color {
    background: yellow;
}
.c-3-color {
    background: green;
}
.c-4-color {
    background: blue;
}
.c-5-color {
    background: purple;
}

.noUi-handle::before,
.noUi-handle::after {
    display: none;
}

#slider-round {
    height: 10px;
}

/* #slider-round .noUi-connect {
    background: #c0392b;
} */

#slider-round .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px;
    border-radius: 9px;
}

.noUi-tooltip {
    display: none;
}
.noUi-active .noUi-tooltip {
    display: block;
}

#slider-round .noUi-handle {
    background: url(../src/icon/trig.png) 50% 50% no-repeat;
    background-size: contain;
    width: 22px;
    height: 28px;  
    top: -17px;
    box-shadow: none; 
    border: medium;
    right: -11px;
}